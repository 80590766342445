import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

const Contact = () => (
  <StaticQuery
    query={graphql`
      query ContactQuery {
        contact: datoCmsContact {
          seoMetaTags {
            ...GatsbyDatoCmsSeoMetaTags
          }
          title
          address
          contacts
        }
      }
    `}
    render={(data) => (
      <article className='sheet'>
        <div className='sheet__inner'>
          <h1 className='sheet__title'>{data.contact.title}</h1>
          <div
            className='sheet__body'
            dangerouslySetInnerHTML={{
              __html: data.contact.address,
            }}
          />
          <div
            className='sheet__body'
            dangerouslySetInnerHTML={{
              __html: data.contact.contacts,
            }}
          />
        </div>
      </article>
    )}
  />
);

export default Contact;
