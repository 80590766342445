import React, { useEffect, useRef, useState } from 'react';
import { ArrowIcon } from './SVGIcons';

const DirectorInfo = ({ director }) => {
  const {name, intro, instagram, vimeo, avatar} = director;
  const [isInfoMode, setIsInfoMode] = useState(false); // ['work', 'info'
  const [infoBarOffset, setInfoBarOffset] = useState(0);
  const [isEventAttached, setIsEventAttached] = useState(false);
  const infoWrapperEl = useRef(null);
  const infoEntryEl = useRef(null);

  const handleClickToClose = (event) => {
    if ( event.target.classList.contains('container__body-content') ) {
      setInfoBarOffset(infoEntryEl.current ? infoEntryEl.current.scrollHeight : 0);  
      setIsInfoMode(false);
      document.body.classList.remove('director-tab-info');
      window.removeEventListener('click', handleClickToClose);
      setIsEventAttached(false);
    }
  }

  const handleDirectorInfoToggle = (event) => {
    if (isInfoMode === false) {
      setInfoBarOffset(infoWrapperEl.current.scrollHeight);
      setIsInfoMode(true);
      window.addEventListener('click', handleClickToClose);
      document.body.classList.add('director-tab-info');
      setIsEventAttached(true);
    } else {
      setInfoBarOffset(infoEntryEl.current.scrollHeight);  
      setIsInfoMode(false);
      document.body.classList.remove('director-tab-info');
      window.removeEventListener('click', handleClickToClose);
      setIsEventAttached(false);
    }
  }

  useEffect(() => {
    setInfoBarOffset(infoEntryEl.current.scrollHeight);

    // Remove transition delay from infoWrapper
    setTimeout(() => {
      if (infoWrapperEl.current) {
        infoWrapperEl.current.style.transitionDelay = '0s';
      }
    }, 500);

    return () => {
      if (isEventAttached) {
        window.removeEventListener('click', handleClickToClose);
        setIsEventAttached(false);
      }
    }
  }, [])
  
  return (
    <div className={`director-info__wrap ${isInfoMode ? 'info' : 'work'}`} ref={infoWrapperEl} style={{ transform: `translateY(-${infoBarOffset}px` }}>
      <div className="director-entry" ref={infoEntryEl}>
        <h6 className="director-entry-title">{name}</h6>
        <div className="directory-entry-toggle">
          <span className={isInfoMode ? 'active' : ''} onClick={handleDirectorInfoToggle}>
            info <ArrowIcon className={isInfoMode ? '' : 'open'} />
          </span>
        </div>
      </div>
      <div className="director-info">
        {avatar && (
          <div className="director-avatar">
            <img sizes={avatar.fluid.sizes} srcSet={avatar.fluid.srcSet} src={avatar.fluid.srcSet} alt={avatar.alt} className="director-avatar-img" />
          </div>
        )}
        <div className="director-content">
          <p className="director-intro">{intro}</p>
          {instagram && (
            <a href={instagram} className="director-social" target="_blank" rel="noopener noreferrer">Instagram</a>
          )}
          {vimeo && (
            <a href={vimeo} className="director-social" target="_blank" rel="noopener noreferrer">Vimeo</a>
          )}
        </div>
      </div>
    </div>
  )
}

export default DirectorInfo;