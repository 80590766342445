import { Link } from 'gatsby';
import React, { useContext, useState } from 'react';
import ReactPlayer from 'react-player';
import WorkContext from '../utils/WorkContext';

const Directors = ({directors, darwerCloseHandler}) => {
  const [hoveredDirector, setHoveredDirector] = useState('');
  const workContext = useContext(WorkContext);

  const handleDirectorHover = (event) => {
    if (event.type === 'mouseenter' && !!event.currentTarget.dataset.featuredVideoUrl) {
      setHoveredDirector(event.currentTarget.dataset.featuredVideoUrl);
      event.currentTarget.classList.add('active-item');
    } else {
      setHoveredDirector(null);
      event.currentTarget.classList.remove('active-item');
    }
  }

  const handleNav = (event) => {
    event.preventDefault();
    const directorInfo = directors.filter(director => director.name === event.currentTarget.name);
    const worksByDirector = workContext.works ? workContext.works.filter(work => work.node.director && (work.node.director.name === event.currentTarget.name)) : [];
    workContext.updateCurrentDirector(directorInfo[0]);
    workContext.updateWorksToDisplay(worksByDirector);
    workContext.updateIsDirectorListing(true);

    darwerCloseHandler();
    window.history.pushState(directorInfo[0].slug, directorInfo[0].name, `/director/${directorInfo[0].slug}`);
  }
  
  return (
    <article className='directors__content'>
      <div className={`sheet__inner ${hoveredDirector ? 'has-focus' : ''}`}>
        {
          directors.map(director => (
            <Link to={`/director/${director.slug}`} className='sheet__title director__text' key={director.id} name={director.name} onMouseEnter={handleDirectorHover} onMouseLeave={handleDirectorHover} activeClassName="current-page" onClick={handleNav} data-featured-video-url={(director.featuredProject && director.featuredProject.videoPreviewUrl) ? director.featuredProject.videoPreviewUrl : ""} >
              {director.name}
            </Link>
          ))
        }
      </div>
      <div className='director__feature'>
          <ReactPlayer
            url={hoveredDirector ? hoveredDirector : null}
            loop={true}
            muted={true}
            controls={false}
            playing={hoveredDirector ? true : false}
            volume={0}
            width='100%'
            height='100%'
          />
        </div>
    </article>
  )
};

export default Directors;