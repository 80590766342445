import { graphql, navigate, StaticQuery } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { isIOSDevice } from 'ios-detector';
import React, { useContext, useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import '../assets/styles/main.scss';
import WorkContext from '../utils/WorkContext';
import Contact from './Contact';
import DirectorInfo from "./DirectorInfo";
import Directors from './Directors';
import ProjectsArchive from './ProjectsArchive';
import SiteHeader from './SiteHeader';

const TemplateWrapper = ({ children, className, page = 'home', customSeo = null }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [featuredWorks, setFeaturedWorks] = useState(null);
  const [workToDisplay, setWorkToDisplay] = useState(null);
  const [archivedWorks, setArchivedWorks] = useState([]);
  const [directors, setDirectors] = useState([]);
  const [isIOS, setIsIOS] = useState(null)
  const contentContainerEl = useRef(null);
  const mainContainerEl = useRef(null);
  const workContext = useContext(WorkContext);

  const [hoveredWork, setHoveredWork] = useState(null);

  const handlePreviewOnHover = (event) => {
    if (event.type === 'mouseenter') {
      setHoveredWork(event.currentTarget.dataset.videoUrl);
    } else {
      setHoveredWork(null);
    }
  };

  const handleLogoClick = () => {
    workContext.updateIsDirectorListing(false);
    workContext.updateWorksToDisplay(featuredWorks);
    setShowMenu(false);
    workContext.updateShowDirectorScreen(false);
    workContext.updateShowArchiveScreen(false);
    workContext.updateShowContactScreen(false);
    contentContainerEl.current.scrollTo(0, 0);
  };

  const closeAllDrawer = () => {
    setShowMenu(false)
    workContext.updateShowArchiveScreen(false)
    workContext.updateShowContactScreen(false);
    workContext.updateShowDirectorScreen(false);
  }

  const handleDrawerToggle = (event, targetDrawer = null) => {
    event.preventDefault()
    const targetDrawerName = targetDrawer || event.currentTarget.id

    if (workContext.isDirectorListing && targetDrawerName === 'toggle--featured') {
      workContext.updateIsDirectorListing(false);
      workContext.updateShowContactScreen(false);
      workContext.updateShowDirectorScreen(false);
      workContext.updateShowArchiveScreen(false);
      workContext.updateWorksToDisplay(featuredWorks);
      navigate('/');
      return;
    }

    setShowMenu(!showMenu)

    // If the call is coming from header component with param
    if (targetDrawer) {
      if (targetDrawer !== 'toggle--contact' && targetDrawer !== 'toggle--director' && targetDrawer !== 'toggle--archive') {
        closeAllDrawer()
      }
    }

    if (targetDrawerName === 'toggle--contact') {
      workContext.updateShowContactScreen(!workContext.showContactScreen);
      workContext.updateShowDirectorScreen(false);
    } else if (targetDrawerName === 'toggle--archive') {
      if (window.location.pathname !== '/') {
        navigate('/');
      }
      if ( workContext.showContactScreen || workContext.showDirectorScreen ) {
        workContext.updateShowDirectorScreen(false);
        workContext.updateShowContactScreen(false);
      }

      // Direct toggle on the archive toggle button
      if (!targetDrawer) {
        workContext.updateShowArchiveScreen(!workContext.showArchiveScreen)
        return
      }

      if (!workContext.showArchiveScreen) {
        workContext.updateShowArchiveScreen(true)
      }
      
    } else if (targetDrawerName === 'toggle--director') {
      if (page !== 'home') {
        navigate('/');
      }
      workContext.updateShowContactScreen(false);
      workContext.updateShowDirectorScreen(!workContext.showDirectorScreen);
      if (workContext.showDirectorScreen) {
        workContext.updateShowDirectorScreen(false);
        workContext.updateIsDirectorListing(false);
        workContext.updateWorksToDisplay(featuredWorks);
      }
    } else if (targetDrawerName === 'toggle--featured') {
      if (window.location.pathname !== '/') {
        navigate('/');
      }
      workContext.updateShowArchiveScreen(false)
      const showcaseEl = contentContainerEl.current.querySelector('.showcase')
      showcaseEl.scrollIntoView({ behavior: "smooth" })
    }
  }

  useEffect(() => {
    if (workContext.works) {
      setFeaturedWorks(workContext.works.filter(work => work.node.featured));
      setWorkToDisplay(workContext.works.filter(work => work.node.featured));
      setArchivedWorks(workContext.works.filter(work => !work.node.featured));
      setDirectors(workContext.directors);
    }

    const hideBarTimer = setTimeout( function(){ window.scrollTo(0, 1); }, 50 );
    setIsIOS(isIOSDevice())
    
    return () => {
      clearTimeout(hideBarTimer)
    }
  }, [workContext])

  return (
    <StaticQuery
      query={graphql`
        query LayoutQuery {
          datoCmsSite {
            globalSeo {
              siteName
            }
            faviconMetaTags {
              ...GatsbyDatoCmsFaviconMetaTags
            }
          }
          datoCmsHome {
            accentColor {
              hex
            }
            seoMetaTags {
              ...GatsbyDatoCmsSeoMetaTags
            }
            copyright
          }
        }
      `}
      render={(data) => {
        if (mainContainerEl.current) {
          mainContainerEl.current.closest('body').style.setProperty('--site-accent', data.datoCmsHome.accentColor.hex);
        }

        return (
          <WorkContext.Consumer>
            {context => {
              return (
                <div className={`container ${showMenu ? 'is-open' : ''} ${className} ${isIOS ? 'site--ios' : ''} ${workContext.isDirectorListing ? 'page-directors' : ''}`} ref={mainContainerEl}>
                  <HelmetDatoCms
                    favicon={data.datoCmsSite.faviconMetaTags}
                    seo={customSeo ? customSeo : data.datoCmsHome.seoMetaTags}
                  />
        
                  <SiteHeader
                    heading={data.datoCmsSite.globalSeo.siteName}
                    logoOnClick={handleLogoClick}
                    containerEl={mainContainerEl}
                    darwerToggleHandler={handleDrawerToggle}
                    currentPage={page}
                  />
                  <div
                    className={`container__body ${
                      workContext.showContactScreen ? 'contact-is-open' : ''
                      } ${workContext.showDirectorScreen ? 'directors-is-open' : ''} ${
                      workContext.showArchiveScreen ? 'archive-is-open' : ''
                      }`}
                  >
                    <div className='director-wrapper'>
                      <div className='directors'>
                        <Directors directors={directors} darwerCloseHandler={closeAllDrawer} />
                      </div>
                      <div
                        className='directors__toggle u-cursor'
                        id="toggle--director"
                        onClick={handleDrawerToggle}
                      >
                        <span className={`directors__toggle-text ${workContext.showDirectorScreen ? 'active' : ''}`}>
                          {workContext.showDirectorScreen ? 'Back' : 'Directors'}
                        </span>
                      </div>
                    </div>
                    <div className='container__body-content' ref={contentContainerEl}>
                      {children}
                      {page === 'home' && !context.isDirectorListing && (
                        <span
                          className={`archive__toggle u-cursor`}
                          id="toggle--archive"
                          onClick={handleDrawerToggle}
                        >
                          <span className='archive__toggle-text'>
                            {workContext.showArchiveScreen ? 'Featured Work' : 'Index'}
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 78 82"><path fill="currentColor" fillRule="nonzero" d="m77.1 43.4-9.7-9.6-21.6 21.6L46 0H32l.2 55.4-21.6-21.6-9.7 9.6L39 81.5z"/></svg>
                          </span>
                        </span>
                      )}
                      {context.isDirectorListing && context.currentDirector && !workContext.showArchiveScreen && (
                        <DirectorInfo director={context.currentDirector} />
                      )}
                    </div>
                    <div className='contact-wrapper'>
                      <div
                        className='contact__toggle u-cursor'
                        id="toggle--contact"
                        onClick={handleDrawerToggle}
                      >
                        <span className='contact__toggle-text u-cursor'>
                          {workContext.showContactScreen ? 'Back' : 'Contact'}
                        </span>
                      </div>
                      <div className='contact'>
                        <Contact></Contact>
                      </div>
                    </div>
                    <div className={`archive`}>
                      <ProjectsArchive
                        projects={workContext.works || []}
                        hoverHandler={handlePreviewOnHover}
                      />
                    </div>
                    <div className='archive__feature'>
                      <ReactPlayer
                        url={hoveredWork ? hoveredWork : null}
                        loop={true}
                        muted={true}
                        controls={false}
                        playing={hoveredWork ? true : false}
                        volume={0}
                        width='100%'
                        height='100%'
                      />
                    </div>
                  </div>
                </div>
              )
            }
          }
        </WorkContext.Consumer>
      )}}
    />
  );
};

export default TemplateWrapper;
