import { motion } from 'framer-motion';
import React from 'react';

export const NavToggle = ({ toggle }) => (
  <div onClick={toggle} className='site-header__nav-toggle u-cursor'>
    <svg width='30' viewBox='0 0 23 18'>
      <Path
        d='M 2 9.423 L 20 9.423'
        variants={{
          closed: { d: 'M 2 2.5 L 20 2.5' },
          open: { d: 'M 3 16.5 L 17 2.5' },
        }}
      />
      <Path
        d='M 2 9.423 L 20 9.423'
        opacity={1}
        variants={{
          closed: { opacity: 1 },
          open: { opacity: 0 },
        }}
      />
      <Path
        d='M 2 9.423 L 20 9.423'
        variants={{
          closed: { d: 'M 2 16.346 L 20 16.346' },
          open: { d: 'M 3 2.5 L 17 16.346' },
        }}
      />{' '}
    </svg>
  </div>
);

const Path = (props) => (
  <motion.path
    fill='transparent'
    strokeWidth='2'
    stroke='currentColor'
    {...props}
  />
);
