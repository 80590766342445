import React from 'react';

export const PlayIcon = ({ clickHandler, className }) => (
  <svg 
    onClick={clickHandler}
    className={className}
    width='24'
    height='24'
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 24 24">
      <path d="M19.7 13.3L5.9 22c-1 .6-2.4-.1-2.4-1.3V3.3c0-1.3 1.4-2 2.4-1.3l13.9 8.7c.9.6.9 2-.1 2.6z" fill="#fff"/>
  </svg>
);

export const ArrowIcon = ({className}) => (
  <svg className={`archive-sort ${className}`} xmlns="http://www.w3.org/2000/svg" width="12" viewBox="0 0 78 82"><path fill="currentColor" fillRule="nonzero" d="m77.1 43.4-9.7-9.6-21.6 21.6L46 0H32l.2 55.4-21.6-21.6-9.7 9.6L39 81.5z"/></svg>
);

export const PauseIcon = ({ clickHandler, className }) => (
  <svg 
    onClick={clickHandler}
    className={className}
    width='24'
    height='24'
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 24 24">
      <path d="M8.6 21.6H4.8c-.6 0-1-.5-1-1V3.4c0-.6.5-1 1-1h3.7c.6 0 1 .5 1 1v17.2c.1.6-.4 1-.9 1zm10.6 0h-3.7c-.6 0-1-.5-1-1V3.4c0-.6.5-1 1-1h3.7c.6 0 1 .5 1 1v17.2c0 .6-.5 1-1 1z" fill="#fff"/>
  </svg>
);

export const CloseIcon = ({ clickHandler, className }) => (
  <svg 
    onClick={clickHandler}
    className={className}
    width="24" 
    viewBox="0 0 23 18">
    <path fill="transparent" strokeWidth="2" stroke="white" d="M 3 16.5 L 17 2.5"></path>
    <path fill="transparent" strokeWidth="2" stroke="white" d="M 2 9.423 L 20 9.423" opacity="0"></path>
    <path fill="transparent" strokeWidth="2" stroke="white" d="M 3 2.5 L 17 16.346"></path> 
  </svg>
);

export const VolumeIcon = ({ clickHandler, className }) => (
  <svg
    onClick={clickHandler}
    className={className}
    width='24'
    height='24'
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg" 
    x="0" 
    y="0" >
    <path fill="#fff" d="M16.7 15.8c-.6 0-1-.4-1-1V9.2c0-.6.4-1 1-1s1 .4 1 1v5.6c0 .5-.4 1-1 1zM21.5 18c-.6 0-1-.4-1-1V7c0-.6.4-1 1-1s1 .4 1 1v10c0 .6-.4 1-1 1zM10.5 3.2L5.9 6.4c-.1.1-.3.2-.5.2h-3c-.5 0-1 .5-1 1.1v8.6c0 .6.4 1.1 1 1.1h3c.2 0 .4.1.5.2l4.5 3.3c.6.5 1.5-.1 1.5-.9V4.1c0-.9-.8-1.4-1.4-.9z"/>
  </svg>
);

export const VolumeMuteIcon = ({ clickHandler, className }) => (
  <svg
    onClick={clickHandler}
    className={className}
    width='24'
    height='24'
    fill="#fff"
    xmlns="http://www.w3.org/2000/svg"
    x="0" 
    y="0" 
    viewBox="0 0 24 24" >
      <path fill='#fff' d="M21.8 15.8c-.3 0-.5-.1-.7-.3l-5.8-5.8c-.4-.4-.4-1 0-1.4s1-.4 1.4 0l5.8 5.8c.4.4.4 1 0 1.4-.2.2-.5.3-.7.3z"/><path class="st0" d="M16 15.8c-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4L21 8.3c.4-.4 1-.4 1.4 0s.4 1 0 1.4l-5.8 5.8c-.1.2-.4.3-.6.3zM10.2 3.2L5.7 6.4c-.2.1-.3.2-.5.2h-3c-.5 0-1 .5-1 1.1v8.6c0 .6.4 1.1 1 1.1h3c.2 0 .4.1.5.2l4.5 3.3c.6.5 1.5-.1 1.5-.9V4.1c0-.9-.8-1.4-1.5-.9z"/>
  </svg>
);

export const FullscreenIcon = ({clickHandler, className}) => (
  <svg 
    onClick={clickHandler}
    className={className}
    width='24'
    height='24'
    xmlns="http://www.w3.org/2000/svg" 
    x="0" 
    y="0" 
    viewBox="0 0 24 24">
      <path fill='#fff' d="M3.8 8.9h-3V4.1c0-1.6 1.3-2.9 2.9-2.9h4.8v3H3.8v4.7zM8.4 22.8H3.6C2 22.8.7 21.5.7 19.9v-4.8h3v4.6h4.6v3.1zM20.4 22.8h-4.8v-3h4.6v-4.6h3V20c0 1.5-1.3 2.8-2.8 2.8zM23.2 8.9h-3V4.2h-4.6v-3h4.8c1.6 0 2.9 1.3 2.9 2.9v4.8z"/>
  </svg>
)