import { Link } from 'gatsby-plugin-modal-routing';
import React, { useEffect, useState } from 'react';
import { ArrowIcon } from './SVGIcons'

const ProjectsArchive = ({ projects, hoverHandler }) => {
  const [projectList, setProjectList] = useState([]);
  const [activeSort, setActiveSort] = useState('custom');
  const [sortDirection, setSortDirection] = useState('asc');

  const handleSort = (event, customSortParam) => {
    const sortType = event ? event.currentTarget.dataset.sort : customSortParam;

    if (sortType === 'custom') {
      setProjectList(projects);
      return;
    }

    const targetSortDirection = activeSort === sortType ? sortDirection === 'asc' ? 'desc' : 'asc' : 'asc';

    const sortedProjects = projects.sort((a, b) => {
      if (sortType === 'title') {
        if (targetSortDirection === 'asc') {
          return a.node.title.localeCompare(b.node.title);
        } else {
          return b.node.title.localeCompare(a.node.title);
        }
      } else if (sortType === 'category') {
        if (targetSortDirection === 'asc') {
          return a.node.category.localeCompare(b.node.category);
        } else {
          return b.node.category.localeCompare(a.node.category);
        }
      } else if (sortType === 'director') {
        if (a.node.director && b.node.director) {
          if (targetSortDirection === 'asc') {
            return a.node.director.name.localeCompare(b.node.director.name);
          } else {
            return b.node.director.name.localeCompare(a.node.director.name);
          }
        } else return false;
      } else if (sortType === 'client') {
        if (targetSortDirection === 'asc') {
          return a.node.client.localeCompare(b.node.client);
        } else {
          return b.node.client.localeCompare(a.node.client);
        }
      }
    });

    setProjectList(sortedProjects);
    setActiveSort(sortType);
    setSortDirection(targetSortDirection);
  }

  useEffect(() => {
    handleSort(null, 'custom');
  }, [projects]);

  return (
    <div className={`archive__inner`}>
      <span className={`archive__divider`}></span>
      <table>
        <thead>
          <tr>
            <th onClick={handleSort} data-sort="title">
              Project
            {activeSort === 'title' ? <ArrowIcon className={sortDirection} /> : ''}
            </th>
            <th onClick={handleSort} data-sort="category">
              Category
              {activeSort === 'category' ? <ArrowIcon className={sortDirection} /> : ''}
            </th>
            <th onClick={handleSort} data-sort="director">
              Director
              {activeSort === 'director' ? <ArrowIcon className={sortDirection} /> : ''}
            </th>
            <th onClick={handleSort} className="archive__col-client" data-sort="client">
              Client
              {activeSort === 'client' ? <ArrowIcon className={sortDirection} /> : ''}
            </th>
          </tr>
        </thead>
        <tbody>
          {projectList.map(({ node: work }) => (
            <tr
              className={work.id}
              key={work.id}
              data-video-url={work.videoPreviewUrl ? work.videoPreviewUrl : ''}
              onMouseEnter={hoverHandler}
              onMouseLeave={hoverHandler}
            >
              <td>
                <Link
                  to={`/work/${work.slug}`}
                  asModal
                  state={{
                    modal: true,
                    noScroll: true,
                  }}
                >
                  {work.title}, 
                  <em className="u-ml-1">{work.subHeading}</em>
                </Link>
              </td>

              <td>
                <Link
                  to={`/work/${work.slug}`}
                  asModal
                  state={{
                    modal: true,
                    noScroll: true,
                  }}
                >
                  {work.category}
                </Link>
              </td>

              <td>
                <Link
                  to={`/work/${work.slug}`}
                  asModal
                  state={{
                    modal: true,
                    noScroll: true,
                  }}
                >
                  {work.director ? work.director.name : 'N/A'}
                </Link>
              </td>
              
              <td>
                <Link
                  to={`/work/${work.slug}`}
                  asModal
                  state={{
                    modal: true,
                    noScroll: true,
                  }}
                >
                  {work.client}
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ProjectsArchive;
