import { motion } from 'framer-motion';
import { Link } from 'gatsby-plugin-modal-routing';
import React, { useState, useContext } from 'react';
import WorkContext from '../utils/WorkContext';
import { Logo } from './Logo';
import { NavToggle } from './NavToggle';

const backgroundVariants = {
  open: {
    top: 0,
    transition: {
      duration: 0.3,
    }
  },
  closed: {
    top: -300,
    transition: {
      duration: 0.3,
    }
  },
};

const SiteHeader = ({ logoOnClick, heading, darwerToggleHandler, currentPage }) => {
  const [isOpen, setIsOpen] = useState(false);
  const themeContext = useContext(WorkContext);

  const handleToggleTheme = () => {
    themeContext.toggleTheme();
  }

  const handleDrawerToggle = (event) => {
    event.preventDefault()
    const {name} = event.currentTarget

    setIsOpen(!isOpen)
    setTimeout(() => {
      if (name === 'home') {
        darwerToggleHandler(event, 'toggle--home')
      } else {
        darwerToggleHandler(event, name)
      }
    }, 300); 
  }

  const handleLogoClick = () => {
    setIsOpen(false)
    logoOnClick()
  }

  return (
    <motion.nav
      className={`site-header site-header--${isOpen ? 'expanded' : 'collapsed'}`}
      intial='closed'
      animate={isOpen ? 'open' : 'closed'}
    >
      <h2>
        <Link onClick={handleLogoClick} className='logo-link' to='/'>
          <Logo className='logo u-cursor' />
          <span className='u-hide-text'>{heading}</span>
        </Link>
      </h2>

      <button type="button" className="toggle toggle--theme" onClick={handleToggleTheme} aria-label="Toggle theme" >
        <svg xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" clipRule="evenodd" viewBox="0 0 24 24"><path fillRule="nonzero" d="M11.998 2c5.517 0 9.997 4.48 9.997 9.998 0 5.517-4.48 9.997-9.997 9.997C6.48 21.995 2 17.515 2 11.998 2 6.48 6.48 2 11.998 2zm0 1.5C7.308 3.5 3.5 7.308 3.5 11.998s3.808 8.497 8.498 8.497z"/></svg>
      </button>

      <NavToggle toggle={() => setIsOpen(!isOpen)} />

      <motion.div className='site-header__nav' variants={backgroundVariants}>
        <nav>
          <ul>
            <li>
              <Link to='/' name="toggle--featured" onClick={handleDrawerToggle}>Featured Work</Link>
            </li>
            <li>
              <Link to='/' name="toggle--director" onClick={handleDrawerToggle} >Directors</Link>
            </li>
            <li>
              <Link to='/' name="toggle--contact" onClick={handleDrawerToggle}>Contact</Link>
            </li>
            <li>
              <Link to='/' name="toggle--archive" onClick={handleDrawerToggle}>Index</Link>
            </li>
          </ul>
        </nav>
      </motion.div>
    </motion.nav>
  );
};

export default SiteHeader;
