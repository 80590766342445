import React from "react";

export const Logo = ({ className, fill }) => (
  <svg
    className={className}
    fill={fill}
    width="588"
    height="620"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMinYMin"
    viewBox="0 0 588 620"
  >
    <g clipPath="url(#a)">
      <path
        fill="#fff"
        d="M252.8 0C467.2 0 588 123.2 588 310.4S456.8 620 269.6 620h-168L0 528.8V0h252.8Zm16.8 598.4c175.2 0 296.8-112.8 296.8-288S455.2 21.6 252.8 21.6H21.6v491.2h227.2c137.6 0 221.6-82.4 221.6-203.2 0-119.2-81.6-202.4-221.6-202.4H136l71.2 64h41.6c92.8 0 147.2 54.4 147.2 138.4 0 85.6-54.4 139.2-147.2 139.2H96V85.6h152.8c152 0 243.2 92.8 243.2 224 0 132.8-93.6 224.8-243.2 224.8H39.2l72 64h158.4Zm-152-171.2H192V188l-74.4-67.2v306.4Zm96-234.4v234.4h35.2c80.8 0 125.6-44 125.6-117.6 0-72-44.8-116.8-125.6-116.8h-35.2Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h588v620H0z" />
      </clipPath>
    </defs>
  </svg>
);
